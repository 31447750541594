<template>
  <section class="howitworks-container">
    <div class="howitworks__text">
      <h4>SLIK FUNGERER DET</h4>

      <p>
        Det finnes kun 1100 Limited Edition Twist tinnbokser i verden, og vi gir
        bort alle! Her gjelder det å registrere seg raskt.
      </p>
    </div>

    <div class="howitworks__bullets">
      <div class="howitworks__bullet">
        <img src="/assets/bullet1.png" />
        <p class="howitworks__bullets__text">
          1. Kjøp to Twist-produkter og behold kvitteringen.
        </p>
      </div>

      <div class="howitworks__bullet">
        <img src="/assets/bullet2.png" />
        <p class="howitworks__bullets__text">
          2. Registrer deg i i skjemaet under.
        </p>
      </div>

      <div class="howitworks__bullet">
        <img src="/assets/bullet3.png" />
        <p class="howitworks__bullets__text">
          3. Motta bekreftelse på e-post.
        </p>
      </div>
    </div>

    <router-link to="/terms">
      <ButtonComponent
        class="howitworks__button"
        theme="primary"
        label="VILKÅR"
      ></ButtonComponent>
    </router-link>
  </section>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.howitworks-container {
  position: relative;
  width: 80%;
  max-width: 1100px;
  border-radius: 25px;
  margin: auto;
  margin-top: 100px;

  text-align: center;

  padding: 50px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
    margin-top: 150px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
    margin-top: 0px;
  }
}

.howitworks {
  &__text {
    max-width: 750px;
    margin: auto;

    h2 {
      margin-top: 25px;
    }
  }

  &__premie {
    width: 90%;
    margin-top: 10px;

    @media (max-width: $mobile) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.357rem;
    }
  }

  &__bullets {
    display: flex;
    justify-content: center;
    gap: 75px;
    margin-top: 40px;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    img {
      width: 100%;
      max-width: 300px;
    }

    &__text {
      font-size: 1.375rem;
    }
  }

  &__bullet {
    width: 30%;

    @media (max-width: $mobile) {
      width: 90%;
    }
  }

  &__button {
    margin: auto;
    margin-top: 50px;
  }
}
</style>
