<template>
  <div>
    <button @click="$emit('click', $event)" class="custom-button">
      <h2>{{ label }}</h2>

      <div v-if="fileSelected">
        <span v-if="isValid" class="custom-button__validation-icon">
          <img
            class="custom-button__icon-valid"
            src="@/assets/elements/validation-correct.svg"
          />
        </span>
        <span v-else class="custom-button__validation-icon">
          <img
            class="custom-button__icon-invalid"
            src="@/assets/elements/validation-incorrect.svg"
          />
        </span>
      </div>
    </button>

    <div v-if="fileSelected && !isValid" class="custom-button__message">
      <p>Bildet må være en jpg/jpeg eller png fil. Vennligst prøv på nytt.</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },
    fileSelected: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  width: 304px;
  height: 62px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  border-radius: 39px;
  background: #04a1cf;
  position: relative;

  h2 {
    color: #000;
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 170%;
  }

  @media (max-width: $mobile) {
    width: 100%;
  }

  &__validation-icon {
    position: absolute;
    top: 17.5px;
    right: 20px;

    @media (max-width: $mobile) {
      top: 20px;
    }
  }

  &__icon-valid {
    color: #33ca62;
    width: 15px;
    height: 15px;
  }

  &__icon-invalid {
    width: 15px;
    height: 15px;
  }

  &__message {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    background: var(--secondary);
    padding: 5px;
    width: 100%;
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    z-index: 10;
    border-radius: var(--vue-elder-border-radius);
    box-shadow: var(--box-shadow);
    z-index: -1;
    visibility: hidden;
    animation-name: showError;
    animation-duration: 4s;

    p {
      color: var(--primary);
      margin: 0;
      font-size: 20px;
    }
  }
}

.custom-button:hover {
  background-color: white;
}
</style>
