<template>
  <div class="container">
    <Error />
  </div>
</template>
<script>
import Error from "@/components/Error.vue";

export default {
  components: {
    Error,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.scrollToTop();
  },
};
</script>

<style lang="scss"></style>
