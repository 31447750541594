<template>
  <div class="participate-parent">
    <img
      class="participate__pattern-desktop"
      src="@/assets/elements/pattern2.jpg"
    />

    <img
      class="participate__pattern-mobile"
      src="@/assets/elements/pattern-mobile.png"
    />

    <section class="participate" id="delta">
      <div class="container">
        <div v-if="!submitted">
          <h1>
            REGISTRERING
          </h1>

          <p class="participate__subtitle">
            Fyll inn informasjon
          </p>

          <form
            @submit.prevent="submit"
            class="participate__form"
            data-netlify="true"
            name="freia-twist-contact"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="freia-twist-contact" />

            <h2 class="participate__label">Personalia</h2>
            <div class="participate__form-inputs">
              <InputComponent
                :placeholder="labels.firstName"
                required
                v-model="data.firstName"
                :is-valid="validator.fields.firstName.valid"
                name="firstName"
              >
              </InputComponent>
              <InputComponent
                :placeholder="labels.lastName"
                required
                v-model="data.lastName"
                name="lastName"
                :is-valid="validator.fields.lastName.valid"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.age"
                required
                v-model="data.age"
                name="age"
                :is-valid="validator.fields.age.valid"
                :validation-message="validator.getFieldError('age')"
              >
              </InputComponent>

              <InputComponent
                :prefix="data.phone ? '+47' : ''"
                :placeholder="labels.phone"
                required
                v-model="data.phone"
                name="phone"
                :is-valid="validator.fields.phone.valid"
                :validation-message="validator.getFieldError('phone')"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.email"
                required
                v-model="data.email"
                name="email"
                :is-valid="validator.fields.email.valid"
                :validation-message="validator.getFieldError('email')"
                class="form-email"
              >
              </InputComponent>
            </div>

            <h2 class="participate__label">Adresse</h2>

            <div class="participate__form-inputs">
              <InputComponent
                :placeholder="labels.street"
                required
                v-model="data.street"
                :is-valid="validator.fields.street.valid"
                name="street"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.streetNumber"
                required
                v-model="data.streetNumber"
                :is-valid="validator.fields.streetNumber.valid"
                name="streetNumber"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.city"
                required
                v-model="data.city"
                :is-valid="validator.fields.city.valid"
                name="city"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.zipCode"
                required
                v-model="data.zipCode"
                :is-valid="validator.fields.zipCode.valid"
                name="zipCode"
              >
              </InputComponent>
            </div>

            <h2 class="participate__required-label">*Påkrevd felt</h2>

            <div class="participate__form-inputs participate__imageinput-grid">
              <InputComponent
                ref="fileInput"
                :is-valid="validator.fields.image.valid"
                name="image"
                type="file"
                v-model="data.image"
                class="imageinput"
                :placeholder="labels.image"
                accept="image/jpeg, image/png, image/jpg"
              >
                <template v-slot:validation-message>
                  <Message value="Bildet må være en jpg/jpeg eller png fil." />
                </template>
              </InputComponent>

              <h2 class="participate__label2">
                Last opp bilde av kvittering her:
              </h2>

              <ImageButton
                type="button"
                :is-valid="validator.fields.image.valid"
                @click="triggerFileInput($event)"
                :fileSelected="fileSelected"
                :label="labels.image"
              ></ImageButton>

              <h2 class="participate__label3">
                <div
                  v-if="data.image && validator.fields.image.valid"
                  class="file-name"
                >
                  {{ fileName.files[0].name }}
                </div>
              </h2>
            </div>

            <CheckboxComponent v-model="termsAccepted">
              <h2 class="participate__terms-font">
                <span>
                  Kryss av her om du godtar
                  <router-link to="/Terms"> konkurransebetingelsene</router-link
                  >.*
                </span>
              </h2>
            </CheckboxComponent>

            <CheckboxComponent v-model="privacyAccepted">
              <h2 class="participate__terms-font">
                <span>
                  Takk for at du deler dine data med oss. Vi behandler alltid
                  personlig data på en respektfull og rettferdig måte, klart
                  begrenset av formålet til denne kampanjen. Vil du vite mer om
                  hvordan vi behandler data, kan du lese mer i vår
                  <a
                    href="https://privacy.mondelezinternational.com/eu/no-NO/privacy-notice/"
                    >personvernerklæring*</a
                  >.
                </span>
              </h2>
            </CheckboxComponent>

            <CheckboxComponent v-model="data.newsletter" name="newsletter">
              <h2 class="participate__terms-font">
                <span>
                  Jeg vil gjerne motta nyheter og informasjon fra Freia.
                </span>
              </h2>
            </CheckboxComponent>

            <div class="participate__buttons">
              <ButtonComponent
                label="SEND INN"
                theme="secondary"
                success-label="Fullført"
                @click="submit"
                :promise="promise"
                error-label="NOE GIKK GALT.."
                loading-label="Laster..."
                :disabled="
                  !validator.passes || !privacyAccepted || !termsAccepted
                "
                class="participate__button"
              />
            </div>
          </form>
        </div>
      </div>
    </section>

    <img
      class="participate__pattern-desktop-bottom"
      src="@/assets/elements/footer-pattern-desktop.png"
    />

    <img
      class="participate__pattern-mobile-bottom"
      src="@/assets/elements/footer-pattern-mobile.png"
    />
  </div>
</template>

<script>
import Validator from "@/mixins/validator";
import { CheckboxComponent } from "vue-elder-checkbox";
import { InputComponent } from "vue-elder-input";
import FileInput from "@/components/FileInput.vue";
import ImageButton from "@/components/ImageButton.vue";
import axios from "axios";
import Compressor from "compressorjs";

import Message from "@/components/Message.vue";

import { Clone } from "@/utils";

const template = {
  firstName: null,
  lastName: null,
  age: null,
  phone: null,
  email: null,
  street: null,
  streetNumber: null,
  city: null,
  zipCode: null,
  image: null,
  imageFile: null,
  newsletter: false,
};

export default {
  mixins: [Validator],
  data() {
    return {
      data: Clone(template),
      termsAccepted: null,
      privacyAccepted: null,
      promise: null,
      disableCodePopover: false,
      submitted: false,
      validator: {
        rules: {
          firstName: "required",
          lastName: "required",
          phone: "required|phone",
          email: "required|email",
          age: "required|numeric|min:18",
          street: "required",
          streetNumber: "required",
          city: "required",
          zipCode: "required",
          image: "required|image",
        },
        labels: "labels",
        data: "data",
      },
    };
  },
  computed: {
    labels() {
      return {
        firstName: "Fornavn*",
        lastName: "Etternavn*",
        email: "E-postadresse*",
        phone: "Mobiltelefon*",
        age: "Din alder*",
        street: "Gateadresse*",
        streetNumber: "Husnummer*",
        city: "Sted*",
        zipCode: "Postnummer*",
        image: "LAST OPP BILDE",
      };
    },
    fileSelected() {
      if (this.data.image) return true;

      return false;
    },
    fileName() {
      return this.$refs.fileInput.$el.querySelector('input[type="file"]');
    },
  },
  methods: {
    triggerFileInput(event) {
      event.preventDefault();
      event.stopPropagation();

      this.$refs.fileInput.$el.querySelector(".elder-input__element").click();
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async executeRecaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute("6Ldpua8pAAAAAEGYQi-EqjRN1yvshWKsQ6zpjXL7", {
              action: "submit",
            })
            .then((token) => {
              resolve(token);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    },
    compressFile(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 500,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    submit() {
      if (!this.validator.passes || !this.privacyAccepted) return;

      this.promise = new Promise(async (resolve, reject) => {
        try {
          const token = await this.executeRecaptcha();

          const fileInput = this.$refs.fileInput.$el.querySelector(
            'input[type="file"]'
          );
          const file = fileInput.files[0];

          if (!file) {
            throw new Error(
              "Du har ikke valgt et bilde. Vennligst velg et bilde og prøv på nytt."
            );
          }

          if (file.type !== "image/jpeg" && file.type !== "image/png") {
            throw new Error(
              "Bilde formatet må være jpeg eller png. Vennligst velg et bilde med riktig format og prøv på nytt."
            );
          }

          this.imageFile = await this.compressFile(file);

          if (this.imageFile.size > 5000000) {
            throw new Error(
              "Bildet er for stort. Vennligst velg et bilde som er mindre enn 5MB."
            );
          }

          const uploadResponse = await fetch("/.netlify/functions/upload", {
            method: "POST",
            headers: {
              "Content-Type": this.imageFile.type,
            },
            body: this.imageFile,
          });

          if (!uploadResponse.ok) {
            throw new Error(`HTTP Error - status: ${uploadResponse.status}`);
          }

          const result = await uploadResponse.json();

          const submitResponse = await fetch("/.netlify/functions/submit", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              firstName: this.data.firstName,
              lastName: this.data.lastName,
              age: this.data.age,
              phone: this.data.phone,
              email: this.data.email,
              street: this.data.street,
              streetNumber: this.data.streetNumber,
              city: this.data.city,
              zipCode: this.data.zipCode,
              image: result.filePath,
              newsletter: this.data.newsletter,
              recaptcha_token: token,
            }),
          });

          // const consultix = await submitResponse.json();

          // console.log(consultix);

          if (!submitResponse.ok) {
            throw new Error(`HTTP Error - status: ${submitResponse.status}`);
          }

          const axiosConfig = {
            header: { "Content-Type": "application/x-www-form-urlencoded" },
          };

          const netlifyResponse = await axios.post(
            "/",
            this.encode({
              "form-name": "freia-twist-contact",
              firstName: this.data.firstName,
              lastName: this.data.lastName,
              age: this.data.age,
              phone: this.data.phone,
              email: this.data.email,
              street: this.data.street,
              streetNumber: this.data.streetNumber,
              city: this.data.city,
              zipCode: this.data.zipCode,
              image: result.filePath,
              newsletter: this.data.newsletter,
            }),
            axiosConfig
          );

          // if (!netlifyResponse.ok) {
          //   throw new Error(`HTTP Error - status: ${netlifyResponse.status}`);
          // }

          const emailResponse = await fetch("/.netlify/functions/send", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: this.data.email }),
          });

          if (!emailResponse.ok) {
            throw new Error(`HTTP Error - status: ${emailResponse.status}`);
          }

          this.$router.push("/thank-you");
          resolve();
        } catch (error) {
          console.error(error);
          this.$router.push("/error");
          reject(error);
        }
      });
    },
    reset() {
      this.data = Clone(template);
      this.termsAccepted = null;
      this.privacyAccepted = null;
    },
  },
  components: {
    CheckboxComponent,
    InputComponent,
    Message,
    FileInput,
    ImageButton,
  },
};
</script>

<style lang="scss">
.participate-parent {
  width: 100vw;
  background-color: var(--primary);

  overflow: hidden;

  position: relative;
}

.participate {
  text-align: center;
  color: var(--primary-contrast);
  max-width: 800px;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 8rem;

  &__label {
    text-align: left;
    font-size: 1.5rem;
    padding: 0px 0px 5px 15px;
    margin-top: 1.5rem;
    color: var(--primary-contrast);
  }

  &__label2 {
    margin: auto;

    color: #000;
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    margin-top: 1rem;
  }

  &__label3 {
    margin: auto;
    text-decoration: underline;
    color: #000;
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    margin-top: 0.5rem;
  }

  &__required-label {
    font-size: 1.25rem;
    color: #d6242a;
    text-align: left;
    padding-left: 1.5rem;
    padding-top: 0.75rem;
    font-weight: 700;
  }

  &__pattern-desktop {
    width: 100%;
    max-height: 150px;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__pattern-mobile {
    width: 100%;
    max-height: 160px;

    @media (min-width: $mobile) {
      display: none;
    }
  }

  &__pattern-desktop-bottom {
    position: absolute;
    width: 100%;
    max-height: 150px;
    bottom: -80px;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__pattern-mobile-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    max-height: 150px;

    @media (min-width: $mobile) {
      display: none;
    }
  }

  @media (max-width: $mobile) {
    width: 90%;
  }

  &__subtitle {
    @media (max-width: $mobile) {
      margin-top: 0.5rem;
    }
  }

  span {
    font-family: var(--secondary-font);

    @media (max-width: $mobile) {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  select {
    border-radius: var(--vue-elder-border-radius) 0 0
      var(--vue-elder-border-radius);
    padding: 0.9em 1.1em;
    font-weight: bold;
    color: #3b1905;
    font-family: "FS ME Bold";
    appearance: none;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;

    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'  stroke='%233b1905' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.25rem;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .elder-button {
      min-width: 20rem;
      margin-bottom: 2rem;

      border: none;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: #000;
  }

  &__form {
    position: relative;

    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;

    @media (max-width: $mobile) {
      max-width: 100%;
    }

    &-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem 1rem;

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }
  }

  .elder-checkbox {
    margin-bottom: 1.5rem;
    text-align: left;

    &--checked {
      .elder-checkbox__box {
        border-color: white;
        background: var(--vue-elder-error);
      }
    }
    align-self: center;

    &__label {
      a {
        text-decoration: underline;
        color: white;
      }
    }

    &__box {
      margin-right: 1rem;
      border-color: white;
      background: white;
      border-color: white;
      border-width: 1px;
      border-radius: 50px;
    }
    &__required {
      font-weight: bold;
      margin-left: 2px;
      color: var(--primary);
    }
  }

  .elder-input {
    &__element {
      border-radius: var(--vue-elder-border-radius) 0 0
        var(--vue-elder-border-radius);
      padding: 25px 30px;
      font-weight: bold;
      color: black;
      font-size: 1.25rem;
    }

    &__prefix {
      font-weight: bold;
      position: relative;
      border-right: none;
      border-radius: var(--vue-elder-border-radius);
      margin: auto;
      padding: 22px;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        height: 40%;
        width: 2px;
      }
    }
    &__field {
      &--focus {
        border-color: white;
      }
      &--invalid {
        border-color: var(--primary) !important;
      }
    }

    &__validation-message {
      p {
        color: var(--primary);
      }

      position: absolute;
      left: 50%;
      top: 45%;

      transform: translate(-50%, -50%);

      color: var(--primary);
      background: var(--secondary);
      padding: 5px;
      width: 100%;
      font-weight: 700;
      text-align: center;
      font-size: 20px;
      z-index: 10;
      border-radius: var(--vue-elder-border-radius);
      box-shadow: var(--box-shadow);
      z-index: -1;
      visibility: hidden;

      @keyframes showError {
        0% {
          overflow: hidden;
          visibility: visible;
          z-index: 1;
        }
        99% {
          overflow: hidden;
          visibility: hidden;
          z-index: 1;
        }
        100% {
          overflow: hidden;
          visibility: hidden;
          z-index: -1;
        }
      }

      animation-name: showError;
      animation-duration: 4s;

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        width: 30px;
        height: 30px;
        path {
          fill: white;
        }
      }
    }
  }

  .v-popover {
    // text-align: right;
    margin: 0.3rem 0;
    // margin-right: 1.5rem;
    .trigger {
      display: flex !important;
      justify-content: space-between;
      padding: 10px 30px;

      @media (max-width: $mobile) {
        flex-direction: column;
        gap: 15px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__terms-font {
    font-size: 1.25rem;
    display: inline;
    color: #000;
    font-weight: 700;
  }

  a {
    color: #000 !important;
  }

  h1 {
    color: var(--primary-contrast);
  }

  &__button {
    background-color: var(--vue-elder-error);
    color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    @media (max-width: $mobile) {
      padding: 0.9rem;

      span {
        font-size: 1.25rem;
      }
    }
  }

  &__imageinput-grid {
    grid-template-columns: 1fr;

    gap: 0.75rem;
    margin-bottom: 2rem;
  }
}

.popover {
  position: relative;
  z-index: 3;
  background-color: var(--secondary);
  color: var(--primary);

  text-align: center;

  padding: 1rem;
  border-radius: 50px;
  max-width: 700px;

  h1 {
    font-size: 3rem;

    @media (max-width: $mobile) {
      font-size: 2rem;
    }
  }

  p {
    margin-top: 10px;
    padding-inline: 3rem;
    font-weight: 700;
    color: var(--primary);
  }

  @media (max-width: $mobile) {
    font-size: 0.8em;
    padding: 0.4rem;
  }

  box-shadow: var(--box-shadow);

  img {
    width: 70%;
    object-fit: contain;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;

    width: 50px;
    height: 50px;
    path {
      fill: var(--primary);
    }
  }
}

.imageinput {
  display: none;

  .elder-input__wrapper {
    display: none;
  }

  .elder-input__field {
    margin: auto;
    cursor: pointer;
    display: none;
  }
}

// #file-upload-button {
//   display: none;
// }

// input[type="file"]::file-selector-button {
//   display: none;
// }

// input[type="file"] {
//   cursor: pointer;
// }
</style>
