<template>
  <div class="file-input-component">
    <label :class="['file-input-button', { invalid: isInvalid }]">
      <input type="file" @change="handleFileChange" :required="required" />
      <span v-if="!isInvalid">
        {{ buttonLabel }}
        <span v-if="isValid" class="checkmark">✓</span>
      </span>
      <span v-else>Please upload image files only</span>
    </label>
    <p v-if="value" class="file-name">{{ value.name }}</p>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    value: {
      type: File,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonLabel: "Last opp bilde",
    };
  },
  computed: {
    isInvalid() {
      return this.isValid === false;
    },
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        this.$emit("input", file);
      } else {
        this.$emit("input", null);
        event.target.value = ""; // Reset the input
      }
    },
  },
};
</script>

<style scoped>
.file-input-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.file-input-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.file-input-button.invalid {
  background-color: #f44336;
}

.file-input-button input[type="file"] {
  display: none;
}

.checkmark {
  margin-left: 5px;
  font-weight: bold;
}

.file-name {
  margin-top: 10px;
  font-style: italic;
}
</style>
