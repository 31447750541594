<template>
  <section class="hero-section">
    <div class="hero-section__textblock-parent">
      <h3>FÅ LIMITED EDITION <br />TINN BOKS</h3>
      <!-- <h3>Kampanjen starter snart</h3> -->

      <img
        src="@/assets/elements/designet-av.png"
        class="hero-section__designet-av"
      />

      <img
        src="@/assets/elements/hero_mobile.png"
        class="hero-section__mobile-image"
      />

      <ButtonComponent
        class="hero-section__button"
        theme="primary"
        label="REGISTRER HER"
        @click="scrollTo"
      ></ButtonComponent>
    </div>

    <div class="hero-section__desktop-image-parent">
      <img
        src="@/assets/elements/hero_desktop.png"
        class="hero-section__desktop-image"
      />
    </div>
  </section>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import { redirect } from "@/utils";

export default {
  components: {
    ButtonComponent,
  },
  methods: {
    scrollTo() {
      const redirectFunc = redirect(["home"], { hash: "#delta" }, this);
      redirectFunc();
    },
  },
};
</script>

<style lang="scss">
.hero-section {
  display: flex;

  margin-top: 14rem;

  @media (max-width: $mobile) {
    margin-top: 9rem;
    overflow: hidden;
  }

  @media (min-width: 2100px) {
    display: block;
  }

  &__textblock-parent {
    display: flex;
    flex-direction: column;

    text-align: center;
    width: 50%;

    margin-left: 3.5rem;

    @media (max-width: $tablet) {
      width: 100%;
      margin: 0;
    }

    @media (min-width: 2000px) {
      width: 100%;
    }
  }

  &__mobile-image {
    @media (max-width: $mobile) {
      width: 175%;
      margin-left: -34%;
    }

    margin: auto;
    width: 80%;
    margin-top: 2rem;

    @media (min-width: $tablet) {
      display: none;
    }
  }

  &__designet-av {
    width: 290px;
    margin: auto;
    margin-top: 1.5rem;

    @media (max-width: $tablet) {
      width: 214px;
    }
  }

  &__desktop-image-parent {
    width: 50%;
    position: relative;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: 2100px) {
      position: static;
    }
  }

  &__desktop-image {
    position: absolute;
    top: -120px;
    right: 0px;
    width: 100%;
    max-width: 750px;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: 2100px) {
      max-width: 550px;
      top: 50px;
    }
  }

  &__signature {
    position: absolute;

    width: 20%;
    top: 100%;
    left: 0%;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__button {
    margin: auto;
    margin-top: 2rem;
  }
}
</style>
