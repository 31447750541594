<template>
  <div class="thankyou-grandparent">
    <section class="thankyou">
      <div class="thankyou__textblock-parent">
        <h4 class="thankyou__text__main-paragraph">
          TAKK FOR DIN REGISTRERING!
        </h4>
        <h2>
          Vi sjekker kvitteringen og sender premien hjem til deg.
        </h2>

        <img
          src="@/assets/elements/hero_mobile.png"
          class="thankyou__mobile-image"
        />

        <div class="thankyou__button-parent">
          <router-link to="/">
            <ButtonComponent
              class="thankyou__button"
              theme="primary"
              label="HJEM"
            ></ButtonComponent>
          </router-link>

          <a
            href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
            target="_blank"
          >
            <ButtonComponent
              class="thankyou__button"
              theme="primary"
              label="KONTAKT OSS"
            ></ButtonComponent>
          </a>
        </div>
      </div>

      <div class="thankyou__desktop-image-parent">
        <img
          src="@/assets/elements/thankyou-pattern.png"
          class="thankyou__desktop-image"
        />
      </div>
    </section>
    <img class="thankyou__pattern" src="@/assets/elements/pattern.png" />
  </div>
</template>
<script>
import Error from "@/components/Error.vue";
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    Error,
    ButtonComponent,
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.scrollToTop();
  },
};
</script>

<style lang="scss">
.thankyou-grandparent {
  min-height: 100vh;
  padding-bottom: 1rem;
  overflow: hidden;
}

.thankyou {
  display: flex;

  margin-top: 14rem;

  @media (max-width: $mobile) {
    margin-top: 9rem;
    overflow: hidden;
  }

  &__textblock-parent {
    display: flex;
    flex-direction: column;

    text-align: center;
    width: 50%;

    margin-left: 3.5rem;
    margin-top: 5rem;

    @media (max-width: $tablet) {
      width: 80%;
      margin: auto;

      h3 {
        padding: 0rem 1rem;
      }
    }

    h4 {
      width: 80%;
      margin: auto;
      margin-bottom: 2rem;
    }

    h2 {
      width: 80%;
      margin: auto;
    }
  }

  &__mobile-image {
    @media (max-width: $mobile) {
      width: 175%;
      margin-left: -34%;
    }

    margin: auto;
    width: 80%;
    margin-top: 2rem;

    @media (min-width: $tablet) {
      display: none;
    }
  }

  &__designet-av {
    width: 290px;
    margin: auto;
    margin-top: 1.5rem;

    @media (max-width: $tablet) {
      width: 214px;
    }
  }

  &__desktop-image-parent {
    width: 50%;
    position: relative;
    max-width: 650px;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__desktop-image {
    position: absolute;
    top: -150px;
    right: 0px;
    width: 90%;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__signature {
    position: absolute;

    width: 20%;
    top: 100%;
    left: 0%;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__button {
    margin: auto;
    margin-top: 2rem;
  }

  &__button-parent {
    margin-top: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media (max-width: $mobile) {
      gap: 0px;
    }
  }

  &__pattern {
    position: absolute;
    left: 0px;
    bottom: 225px;

    width: 175px;

    @media (max-width: $tablet) {
      display: none;
    }
  }
}
</style>
