<template
  ><div class="terms-container-parent">
    <div class="terms-container">
      <div class="terms-container__text">
        <h4>VILKÅR</h4>

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span>
                FÅ TWIST TINNBOKS: KJØP TO TWIST-POSER FÅ EN TINNBOKS
              </span>
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>ARRANGØR AV KONKURRANSEN</span>

              <br />
              Mondelez Europe Services - Norsk filial (Mondelez/Arrangøren)
              <br />Johan Throne Holsts Plass 1, <br />P.O. Box 6658
              Rodeløkka,<br />NO-0502, Oslo
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Pulse Communications AS, (Pulse Communications) Askekroken 11,
              0277 Oslo, Norge, håndterer det tekniske rundt konkurransen. Pulse
              Communications håndterer trekningen av konkurransen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>AKSEPT AV VILKÅR OG BETINGELSER</span><br />

              Ved å delta i konkurransen aksepterer deltakeren
              konkurransereglene. Denne kampanjen er åpen for innbyggere i Norge
              som er fylt 18 år, med unntak av arrangørens ansatte,
              representanter og alle andre med en profesjonell tilknytning til
              denne kampanjen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>PREMIE</span>

              <br />

              Premien er en unik limited edition tinnboks med Twist design.
              Designet av Fam Irvoll.

              <br />
              <br />

              Det er totalt 1100 tinnbokser med eget Twist design som deles ut.
              De første 1000 som registrerer seg med gyldig kvittering(er) for
              kjøp av to Twist-poser vil få tilsendt en tinnboks i posten. De
              siste 100 tinnboksene vil bli trukket etter endt kampanjeperiode.

              <br />
              <br />

              Man kan delta så mange ganger man vil, men det er kun én premie
              per deltaker.

              <br />
              <br />

              Premien må aksepteres slik som den er, og kan ikke byttes inn mot
              kontanter. Mondelez er ikke ansvarlige for eventuelle skader som
              måtte oppstå under transport/utsendelse av delpremien.

              <br />
              <br />

              Mottaker må selv ta ansvar for eventuell gevinstskatt.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>KAMPANJEPERIODE</span>
              <br />

              Mandag 14 oktober 2024 og så lenge lageret rekker.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>HVORDAN DELTA</span>
              <br />
              1. Kjøp to Twist poser (145g, 300g, 330g, 450g)

              <br />
              2. Scan QR koden på materiellet i butikk, eller gå inn på freia.no
              eller twist.freia.no for å delta
              <br />
              3. Fyll ut det elektroniske skjemaet med dine opplysninger. Du må
              laste opp kvittering(er) for kjøp av to Twist poser kjøpt i
              kampanjeperioden.
              <br />
              4. De første 1000 som registrerer seg får tilsendt en Twist
              tinnboks i posten ved korrekt utfylling av deltakerskjema
              <br />
              5. De resterende 100 vil bli trukket etter endt kampanjeperiode.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>SLIK BLIR MOTTAKER PLUKKET UT </span>

              <br />

              De første 1000 som registrerer seg med gyldig kvittering på kjøp
              av to Twist-poser får en Twist Limited Edition-tinnboks tilsendt i
              posten. De siste 100 trekkes etter endt kampanjeperiode.

              <br />
              Man kan delta så mange ganger man vil, men man kan kun motta én
              premie. .

              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>DISKVALIFISERING</span>
              <br />
              Mondelez og andre partnere forbeholder seg retten til å
              diskvalifisere deltakere som bryter med konkurransereglene, deltar
              med upassende kommentarer eller forsøker å sabotere konkurransen.
              Disse deltagerne vil også bli utestengt. Upassende eller støtende
              kommentarer fjernes.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>FORBEHOLD</span>
              <br />
              Mondelez og andre partnere i denne konkurransen tar ikke ansvar
              for eventuelle trykkfeil eller andre utilsiktete misforståelser.
              <br />
              <br />

              Hvis, av en eller annen grunn, kampanjen ikke kan bli gjennomført
              som planlagt, vil Mondelez reservere seg retten til å, når som
              helst og uten forvarsel, avbryte, avslutte, endre eller utsette
              hele eller deler av kampanjen.
              <br />
              <br />

              Arrangørene vil ikke være ansvarlige for kampanjen eller premien,
              og tar ikke særlig ansvar for deltakelse som er ugyldig på grunn
              av tekniske problemer utenfor arrangørens kontroll eller problemer
              angående tilgang til nettsiden.

              <br />
              <br />

              Alle internettkostnader og andre kostnader som kan oppstå
              forbundet med deltakelse i kampanjen, er utelukkende deltakerens
              ansvar.
            </p>

            <br />

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>PERSONOPPLYSNINGER</span>
              <br />

              Ved deltagelse i konkurransen kommer deltakeren til å måtte gi fra
              seg visse personopplysninger, som navn og kontaktinformasjon.
              Mondelez vil behandle personopplysninger for å oppfylle sine
              forpliktelser vedrørende konkurransen, blant annet for å kontakte
              deltakerne og dele ut premiene. Etter at vi har delt ut premiene,
              vil listen over kontaktopplysninger bli slettet. Listen slettes
              senest 12 måneder etter at kampanjen er avsluttet.

              <br />
              <br />
              Dine personopplysninger vil bli delt med nøye utvalgte
              tredjeparter, Consultix som lagrer personopplysningene, og Pulse
              Communications som håndterer trekkingen og det tekniske rundt
              konkurransen. Mondelez er i slike tilfeller
              personopplysningsansvarlig og tredje part er prosessfullmektige.
              Ved å delta i konkurransen og akseptere disse vilkårene, samtykker
              du til at Mondelez behandler og gir videre dine personopplysninger
              til ovenfor beskrevne gjøremål.
              <br />
              <br />
              Du har rett til å tilbakekalle ditt samtykke til behandlingen av
              personopplysninger. Tilbakekalles dette samtykket innen mottaker
              er trukket, anses du ikke lengre å oppfylle kravene for å delta i
              konkurransen, og vi kommer ikke til å benytte dine
              personopplysninger videre. Du har rett til å gratis, en gang per
              kalenderår, etter skriftlig underskrevet søknad stilet til
              Mondelez, å få beskjed om hvilke personopplysninger Mondelez har
              om deg og hvordan disse behandles. Etter loven har du også rett
              til å be om korrigering av eventuelle uriktige personopplysninger
              Mondelez måtte ha.
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              For mer informasjon om behandling av personopplysninger,se her:
              <br />
              <a
                href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d"
                target="_blank"
              >
                https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d
              </a>

              <br />
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>KONTAKT</span>
              <br />
              Har du spørsmål om vilkårene eller konkurransen generelt,kan du ta
              kontakt via Mondelez forbrukerkontakt, tlf. 21 53 08 57.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <img
      class="terms-footer__pattern-desktop-bottom"
      src="@/assets/elements/footer-pattern-desktop.png"
    />

    <img
      class="terms-footer__pattern-mobile-bottom"
      src="@/assets/elements/footer-pattern-mobile.png"
    />

    <div class="terms-footer">
      <h4>
        SJEKK UT KONKURRANSEN
      </h4>
      <router-link to="/">
        <ButtonComponent
          class="terms-footer__button"
          theme="primary"
          label="REGISTRERING"
        ></ButtonComponent>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.terms-container-parent {
  @media (max-width: $mobile) {
    position: relative;
    overflow-x: clip;
  }
}

.terms-container {
  position: relative;

  width: 80%;
  max-width: 1100px;

  border-radius: 25px;
  margin: auto;
  margin-top: 125px;

  text-align: center;

  padding: 75px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
    margin-top: 85px;
  }

  &__image {
    position: absolute;
    width: 265px;
    left: -12.69%;
    top: 50px;

    @media (max-width: 1375px) {
      display: none;
    }
  }

  &__text {
    max-width: 820px;
    margin: auto;

    &__main-paragraph {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 28.8px;
      margin-bottom: 1.5rem;
    }

    &__sub-paragraph {
      text-align: left;
      line-height: 25px;

      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
      }

      &__subtitle {
        font-weight: 400;

        &__main {
          @media (max-width: $mobile) {
            // max-width: 40%;
            word-break: normal;
          }
        }
      }
    }
  }

  h2 {
    margin-top: 16px;

    @media (max-width: $mobile) {
      max-width: 40%;
      word-break: normal;
    }
  }

  p {
    line-height: 1.6rem;
    font-size: 1.125rem;
  }

  &__button-parent {
    margin-top: 75px;
    overflow: hidden;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    line-break: anywhere;
    color: white;
  }

  span {
    font-weight: 900;
    font-family: var(--paragraph-font);
    line-height: 26px;

    @media (max-width: $mobile) {
      font-size: 1.375rem;
    }
  }
}

.terms-footer {
  position: relative;
  width: 80%;
  max-width: 1100px;
  border-radius: 25px;
  margin: auto;
  margin-top: -3.5rem;

  text-align: center;
  padding: 75px 0px;

  @media (max-width: $mobile) {
    padding: 0px 0px 75px 0px;
  }

  &__button {
    width: 80%;
    max-width: 300px;
    margin: auto;
    margin-top: 2rem;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    color: white;
  }

  &__pattern-desktop-bottom {
    width: 100%;
    max-height: 150px;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__pattern-mobile-bottom {
    width: 100%;
    max-height: 150px;
    margin-bottom: 3rem;

    @media (min-width: $mobile) {
      display: none;
    }
  }
}
</style>
