export default (Validator) => {
  Validator.register(
    "image",
    function(value) {
      return (
        value.endsWith("jpg") || value.endsWith("png") || value.endsWith("jpeg")
      );
    },
    ":attribute Bildet må være en jpg/jpeg eller png fil."
  );
};
