import { render, staticRenderFns } from "./TermsSection.vue?vue&type=template&id=7f3ad53b&"
import script from "./TermsSection.vue?vue&type=script&lang=js&"
export * from "./TermsSection.vue?vue&type=script&lang=js&"
import style0 from "./TermsSection.vue?vue&type=style&index=0&id=7f3ad53b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports