<template>
  <div class="container">
    <HeroSection />

    <HowItWorks />
    <About />
    <Form />
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import Form from "@/components/Form.vue";
import HowItWorks from "@/components/HowItWorks.vue";
import About from "@/components/About.vue";

export default {
  components: {
    HeroSection,
    Form,
    HowItWorks,
    About,
  },
};
</script>

<style lang="scss"></style>
